<template>
  <div class="page">
    <div class="headBgc"></div>
    <div class="navHead">
      <div class="nav">
        <div class="navName">意向问卷</div>
        <img
          :src="$ossRes('/common/back.png')"
          alt=""
          class="shareIcon"
          @click="back"
        />
      </div>
    </div>
    <div class="content">
      <div class="content-item" v-for="(i, k) in template" :key="k">
        <div class="item-title">
          <p>{{ `${k + 1}.${i.title}` }}</p>
        </div>
        <div class="item-choice">
          <div class="choice-radio" v-if="i.type === 'radio'">
            <van-radio-group
              v-model="template[k].choice"
              direction="horizontal"
            >
              <van-radio
                :name="m.name"
                v-for="(m, n) in i.child"
                :key="n"
                icon-size="16"
                checked-color="#F4303F"
              >
                {{ m.name }}
                <input
                  v-model="template[k].other"
                  @click.stop.prevent="() => {}"
                  v-if="m.extra"
                  class="item-other"
                  :placeholder="m.extrapla ? m.extrapla : '请填写'"
                />
              </van-radio>
            </van-radio-group>
          </div>
          <div class="choice-checkbox" v-if="i.type === 'checkbox'">
            <van-checkbox-group
              v-model="template[k].choice"
              direction="horizontal"
            >
              <van-checkbox
                :name="m.name"
                shape="square"
                v-for="(m, n) in i.child"
                :key="n"
                icon-size="16"
                checked-color="#F4303F"
              >
                {{ m.name }}
                <input
                  v-model="template[k].other"
                  @click.stop.prevent="() => {}"
                  v-if="m.extra"
                  class="item-other"
                  :placeholder="m.extrapla ? m.extrapla : '请填写'"
                />
              </van-checkbox>
            </van-checkbox-group>
          </div>
        </div>
      </div>
    </div>
    <div class="footerBtn">
      <div class="sureClick" @click="submit">提交</div>
    </div>
  </div>
</template>
<script>
import { wxShare } from "@/assets/js/wechatAuth.js";
export default {
  data() {
    return {
      feedDate: {},
      form: {},
      userId: "",
      template: [],
      templateOrigin: [],
    };
  },
  created() {
    this.userId = this.$getUserInfo().id;
    this.getIntentionTemplate();
  },
  methods: {
    radioChange(e, m, n){
      console.log(e, m, n, 'radioChange')
    },
    checkboxChange(e, m, n){
      console.log(e, m, n, 'checkboxChange')
    },
    back() {
      this.$router.back();
    },
    getIntentionTemplate() {
      this.$http.get("/sale/h5/getIntentionTemplate", {
        user_id: this.userId
      }).then((res) => {
        if (res.code === 200 && res.data) {
          this.templateOrigin = res.data;
          this.template = res.data;
        }
      });
    },
    submit() {
      let isFail = false,
        otherFail = false;
      this.template.map((i, k) => {
        if (!i.choice || i.choice.length <= 0) {
          isFail = true;
        } else {
          if (i.type === "radio") {
            this.templateOrigin[k].child.map((m, n) => {
              if (m.name === i.choice) {
                this.templateOrigin[k].child[n].checked = 1;
                if (m.extra) {
                  if (i.other) {
                    this.templateOrigin[k].child[n].value = i.other;
                  } else {
                    otherFail = true;
                  }
                }
              } else {
                this.templateOrigin[k].child[n].checked = 0;
              }
            });
          } else if (i.type === "checkbox") {
            this.templateOrigin[k].child.map((m, n) => {
              if (i.choice.includes(m.name)) {
                this.templateOrigin[k].child[n].checked = 1;
                if (m.extra) {
                  if (i.other) {
                    this.templateOrigin[k].child[n].value = i.other;
                  } else {
                    otherFail = true;
                  }
                }
              } else {
                this.templateOrigin[k].child[n].checked = 0;
              }
            });
          }
        }
      });
      if (isFail) {
        this.$toast.fail("都是必选哟~");
        return;
      }
      if (otherFail) {
        this.$toast.fail("选了其他就得填写哟~");
        return;
      }
      if (!this.userId) {
        this.$toast.fail("请先登录哟~");
        return;
      }
      const content = this.templateOrigin.map(({ title, type, child }) => {
        return {
          title,
          type,
          child,
        };
      });
      this.$http
        .postJson(
          "/sale/h5/addIntention",
          {
            user_id: this.userId,
            sale_user_id: localStorage.getItem("sale_user_id"),
            content: content,
          },
          true
        )
        .then((res) => {
          if (res.code === 200 && res.data) {
            this.$toast.success("提交成功");
            setTimeout(() => {
              this.$router.replace({
                name: "Home",
              });
            }, 1000);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}
.page {
  position: relative;
  width: 100%;
  padding-bottom: 120px;
  .headBgc {
    position: absolute;
    top: 0;
    width: 100%;
    height: 478px;
    background: url("@{PublicEnv}/common/caseBgc.png");
    background-size: 100% 100%;
    z-index: 0;
  }
  .navHead {
    position: relative;
    width: 100%;
    height: 184px;
    z-index: 10;
    .nav {
      position: absolute;
      bottom: 0;
      width: 100%;
      padding: 0 48px;
      box-sizing: border-box;
      height: 96px;
      line-height: 96px;
      text-align: center;
      .navName {
        font-size: 36px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
      }
      .shareIcon {
        position: absolute;
        left: 48px;
        bottom: 23px;
        width: 50px;
        height: 54px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 15;
    margin-top: 16px;
    .content-item {
      position: relative;
      width: 654px;
      margin: 0 auto;
      margin-bottom: 30px;
      box-sizing: border-box;
      padding: 36px 30px;
      background: #fcfcfc;
      box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.101);
      border-radius: 16px 16px 16px 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      &:last-child {
        margin-bottom: 0;
      }
      &::before {
        position: absolute;
        content: "";
        width: 14px;
        top: 70px;
        bottom: 70px;
        background: #d6191f;
        border-radius: 16px 0 0 16px;
        left: -14px;
      }
      .item-title {
        width: 100%;
        p {
          text-align: left;
          font-size: 32px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
      }
      .item-choice {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 26px;
        margin-right: 100px;
        margin-top: 18px;
        :deep(.van-radio) {
          margin-bottom: 26px;
        }
        :deep(.van-checkbox) {
          margin-bottom: 26px;
        }
        :deep(.van-radio__label) {
          font-size: 24px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          margin-left: 26px;
          min-width: 220px;
          text-align: left;
        }
        :deep(.van-checkbox__label) {
          font-size: 24px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          margin-left: 26px;
          min-width: 220px;
          text-align: left;
        }
        .item-other {
          margin-left: 10px;
          width: 160px;
          height: 40px;
          background: #f6f6f6;
          border-radius: 4px 4px 4px 4px;
          border: 0;
          text-align: center;
        }
      }
    }
  }
  .footerBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 62px;
    .sureClick {
      width: 554px;
      height: 96px;
      line-height: 96px;
      background: #d6161c;
      box-shadow: 0px 0px 40px 0px rgba(83, 83, 83, 0.302);
      border-radius: 48px 48px 48px 48px;
      opacity: 1;
      font-size: 32px;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
@media screen and (orientation: landscape) {
  .page {
    min-width: 750px !important;
    max-width: 850px !important;
    margin: auto !important;
    overflow: hidden !important;
    border-radius: 0 !important;
    border: 0 !important;
    padding: 0 !important;
  }
}
</style>
